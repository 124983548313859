@font-face {
  font-family: Garamond-Regular;
  src: url('./Assets/Fonts/Garamond/EBGaramond-Regular.ttf');
}
@font-face {
  font-family: Garamond-SemiBold;
  src: url('./Assets/Fonts/Garamond/EBGaramond-SemiBold.ttf');
}
@font-face {
  font-family: Garamond-Bold;
  src: url('./Assets/Fonts/Garamond/EBGaramond-Bold.ttf');
}
@font-face {
  font-family: Garet-Heavy;
  src: url('./Assets/Fonts/Garet/Garet-Heavy.ttf');
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.link{
  text-decoration: none;
  color: white;
}
html, body {
  width: 100%;
  height: 100%;
  /*overflow-x: hidden;*/
}
.App {
  width: 100%; /* Ensure it spans the full width */
  height: 100%;
  min-height: 100vh; /* Ensure it takes up at least the full height of the viewport */
  text-align: center;
  background-image: url("./Assets/Backgrounds/Background_S2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
body{

}
#root {
  width: 100%;
  height: 100%;
}
.container {
  min-width: 100vw;
  height: 100%;  /* Ensure it takes full height too */
  /*overflow: hidden;*/
  margin: 0 auto;
  padding: 0 !important;
}
.App-header {
  width: 100%; /* Ensure it takes up full width */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 0;
  padding: 0;
  background: none;
  z-index: 0; /* Make sure it's on top if needed */
  position: relative;
}

/*Working on various text type*/
.title, .subtitle, .text{
  color: #ffffff;
}
.title, .subtitle{
  font-family: Garet-Heavy;
}
.primary-heading{
  font-size: calc(30px + 7.5vmin);
  color: #ffffff;
  font-family: Garet-Heavy;
}

.card{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}