
#events-div{
    background-image: url("./../../Assets/Backgrounds/Background_S2.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    justify-content: flex-start;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    position: relative;
    height: auto;
}
.events-information{
    padding: 0rem 1rem 0rem 0rem;
}
/* Wrapper for the DNA SVG */
.dna-wrapper {
    position: relative;
    display: inline-block;
    height: auto;
    width: 400px;
    padding: 0rem 8rem 0rem 0rem;
}

/* DNA SVG styling */
.dna-svg {
    width: 100%;
    height: 135vh;
    display: block;
    margin: 0 auto;
}

/* Event card styling */
.event-details {
    position: absolute;
    padding: 10px;
    color: white;
    text-align: left;
    width: 32.5vh;
    /*transform: translate(-50%, -50%);*/
    transition: transform 0.3s ease, background-color 0.3s ease;
}
.event-details:hover {
    transform: scale(1.05);
}
.event-details h3, p{
    font-size: 1.25rem;
}

/* Event title */
.event-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

/* Event category */
.event-category {
    font-style: italic;
    margin-bottom: 0.5rem;
}


@media (min-aspect-ratio: 1){
    /* Wrapper for the DNA SVG */
    .dna-wrapper {
    }

    /* DNA SVG styling */
    .dna-svg {
        width: 100%;
    }
}