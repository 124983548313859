#about-div{
    background-image: url("./../../Assets/Backgrounds/Background_S2.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: auto;
    width: 100%;
}

.primary-heading{
    margin: 2rem 0rem 0rem 0rem;
}

#about-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    padding: 0.5rem 1rem 2rem 1rem !important;
    margin: 2rem 0rem 0rem 0rem;

    background-color: rgba(116, 136, 116, 0.3);
    border-radius: 15px;
    transition: transform 0.3s ease, background-color 0.3s ease;

    height: 50vh;
    overflow-y: auto;
    box-sizing: border-box;
}
#about-card:hover{
    transform: scale(1.02);
}
#first-text-about{
    margin-top: 2rem;
}
.text{
    color: #ffffff;
}

@media (max-aspect-ratio: 0.9){
    #about-div{
        width: 100%;
    }

    #about-card{
        width: 95%;
        padding: 2rem 1rem 2rem 1rem;
        margin: 2rem 0rem 0rem 0rem;

        border-radius: 15px;
    }
    #about-card:hover{
        transform: scale(1.02);
    }
    .text{
        font-size: 1rem;
    }

}