#heading-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-image: url("./../../Assets/Backgrounds/Background_S1.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: 100vh;

}

#genesis-subtitle{
    font-family: Garamond-Regular;
    margin: 0.75rem 0rem 0.75rem 0rem;
}
#genesis-text-img{
    height: 25vh;
}
#genesis-date{
    font-size: 3rem;
    color: #000000;
    /*color: #755685;*/
    margin: 0.75rem 0rem 0.75rem 0rem;
}
.primary-button{
    background-color: #223622;
    height: 7.5vh;
    width: 12.5vw;
    border-radius: 50px;
    border: none;
    color: white;
    transition: transform 0.3s ease, color 0.3s ease;
}
.primary-button:hover{
    transform: scale(1.1);
}
@media (max-aspect-ratio:1.5){
    #genesis-text-img{
        height: 20vh;
    }
    #genesis-date{
        font-size: 3rem;
        margin: 0.75rem 0rem 0.75rem 0rem;
    }
    .primary-button{
        height: 7.5vh;
        width: 25vw;
    }
}
@media (max-aspect-ratio:0.9){
    #heading-div{

    }

    #genesis-subtitle{
        /*font-size: 3rem;*/
    }
    #genesis-text-img{
        height: 15vh;
    }
    #genesis-date{
        font-size: 3rem;
        margin: 0.75rem 0rem 0.75rem 0rem;
    }
    .primary-button{
        height: 7.5vh;
        width: 25vw;
    }
}
@media (max-aspect-ratio:0.8){
    #heading-div{

    }

    #genesis-subtitle{
        /*font-size: 3rem;*/
    }
    #genesis-text-img{
        height: 10vh;
    }
    #genesis-date{
        font-size: 2rem;
        margin: 0.75rem 0rem 0.75rem 0rem;
    }
    .primary-button{
        height: 7.5vh;
        width: 30vw;
    }
}