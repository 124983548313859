#team-div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding: 5rem 0rem 0rem 0rem;
    width: 100%;

    background-image: url("./../../Assets/Backgrounds/Background_S2.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    height: auto;
}

#team-carousel-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: rgba(116, 136, 116, 0.3);
    height: 55vh;

    width: 55%;
    border-radius: 15px;
}

#team-type{
    color: #FFFFFF !important;
    margin: 3.5rem 0rem 1rem 0rem !important;
}

#carousel-member-info{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.nav-button{
    background: none;
    border: none;
    height: 5vh;

    transform: scale(1.75);
    margin: 0rem 1rem 0rem 1rem;
    transition: transform 0.3s ease, background-color 0.3s ease;
}
.nav-button:hover{
    transform: scale(2.5);
}
.member-image{
    /*transform: scale(0.5);*/
    height: 30vh;
    border-radius: 50%;
}
.member-name{
    color: #FFFFFF !important;
    font-size: 1.6rem;
    font-weight: 700;
    margin: 2rem 0rem 0rem 0rem;
}
.hods-img{
    /*border-radius: 50%;*/
    padding: 1rem 0rem 2rem 0rem;
    height: auto;
}
#hod-team-image{
    height: 50vh;
    width: auto;
    border-radius: 25px;
}
#eventheads-team-image{
    height: 50vh;
    width: auto;
    border-radius: 25px;
}
#team-type{
    /*text-decoration: underline ;*/
    color: #282c34;
    text-shadow: 0.01rem 0.01rem #505050;

    margin-top: 1rem;
}
#carousel-member-info{
    display: flex;
    flex-direction: column;
}
.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 55% ;
    margin: 0rem 2rem;
    background-color: rgba(116, 136, 116, 0.3) !important;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, color 0.3s ease;
    padding-top: 2rem;
}
.card:hover {
    transform: scale(1.05);
}

#hods-card-top-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 1rem;
}
#hod-team-card{
    background-color: rgba(116, 136, 116, 0.3) !important;
}


#team-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
#team-info p{
    color: #ffffff;
}
#hods-card-bottom-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 1rem;
}


.card-name {
    font-size: 1.8rem;
    font-weight: 700;
    text-align: center;
    color: #282c34;
}

.card-secondary-text {
    font-size: 1.2rem;
    margin: 0.5rem 0;
    text-align: center;
    color: #505050;
}
.team-info-text-line{
    margin: 0;
}
.member-text{
    margin: 1.5rem 0;
}
@media (max-aspect-ratio: 1.3){
    #team-carousel-div{
        width: 80%;
    }
    .card{
        width: 80%;
    }
    #hod-team-image{
        height: 35vh;
    }
    #eventheads-team-image{
        height: 35vh;
    }
}

@media (max-aspect-ratio: 0.9){
    #team-carousel-div{
        width: 80%;
    }
    .card{
        width: 80%;
    }
    #hod-team-image{
        height: 25vh;
    }
    #eventheads-team-image{
        height: 25vh;
    }
}