.custom-navbar {
    position: fixed;
    top: 0;
    z-index: 1000;
    background-color: #755685;
    transition: background-color 0.3s ease;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
}

.navbar-links{
    color: #ffffff;
    font-size: 1.25rem;
    font-weight: bold;
    padding: 0rem 1rem 0rem 1rem;
    text-decoration: none;
    font-family: Garamond-Regular;

}
#basic-navbar-nav{
    margin-right: 2rem;
}
#genesis_logo{
    margin-left: 2rem;
}
.navbar-links:hover{
    cursor: pointer;
}

#genesis_logo{
    height: 4rem;
}

@media (max-aspect-ratio: 0.9){
    #genesis_logo{
        margin-left: 1rem;
    }
}