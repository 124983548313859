#events-desc-div{
    background-image: url("./../../Assets/Backgrounds/Background_S2.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    margin: 0;
    width: 100%;
    height: auto;
    padding: 2rem 0rem 0rem 0rem !important;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
#events_desc_title{
    margin: 0rem;
}
.event-cards-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

#event-card{
    width: 20%;
    height: 30vh;
    margin: 1rem 1rem 1rem 1rem;
    padding: 0.5rem 1rem 0.5rem 1rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.event-name{
    color: white;
    font-weight: 800;
}
.event-description{
    color: white;
    font-weight: 300;
    overflow-y: auto;
}

@media (max-aspect-ratio: 1.5){
    .event-cards-container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    #event-card{
        width: 50vh;
    }
}